import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_solutions.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p className="leading-relaxed text-lg">
  In addition to giving access to all your outdoor activity content, the
  Trailguide app can also be used by your guests as a navigation app to find the
  activities and to stay on track. Providing up-to-date information about
  activities, conditions and closing or opening routes helps you manage and
  distribute the traffic pattern of your guests.
    </p>
    <br />
    <br />
    <h4>{`Navigation tool`}</h4>
    <p>{`The app can be used to find activities and navigate on tracks. Clicking the
prominently displayed location-button will show the user's current position
on the map.`}</p>
    <br />
    <p>{`With our selection of detailed topographical maps navigating
outdoors is easy and enjoyable.`}</p>
    <br />
    <p>{`The GPX tracks of every activity can be downloaded by the user and used for
offline navigation access on any GPS device.`}</p>
    <Image src="solutions/navigation2.png" className="my-12 w-3/4 mx-auto" mdxType="Image" />
    <br />
    <br />
    <h4>{`Traffic management and distribution`}</h4>
    <p>{`Reduce maintenance costs by spreading the traffic onto more trails to reduce
the load on few popular ones or channel the traffic to the more robust and
sustainable trails.`}</p>
    <br />
    <p>{`Publish and make visible trails to attract traffic, or hide trails to avoid
areas of conflict such as private properties, during hunting season,
forestry work, or while trail building and maintenance.`}</p>
    <br />
    <p>{`Provide a positive user experience and protect your trails by guiding your
guests onto trails that are in a good condition. Avoid trails that are
affected by heavy rain falls, or snow and ice.`}</p>
    <Image src="solutions/maintenance.png" className="mt-12 w-3/4 mx-auto" mdxType="Image" />
    <div className="text-center mt-2 mb-12 italic text-sm text-gray-700">
  avoid experiences like this by channeling and distributing traffic
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      